<template>
    <div class="all-music m-spill container">
        <!--<a-button class="filter_open" v-if="filter_button" @click="changefilerDisplay(1)">筛选  <a-icon type="right" /></a-button>-->
        <span class="filter_open" v-if="filter_button" @click="changefilerDisplay(1)">筛选
            <a-icon type="right" /></span>
        <div class="filter_music" id="boxFixed" v-if="!isfixed" :style="filterStyle">
            <div class="filter_head">
                <h3>
                    <img src="@/assets/images/web/music/sliders.svg" style="width: 24px" />
                    筛选条件
                </h3>
                <p>
                    <span style="
              font-size: 12px;
              color: #ff7b13;
              font-weight: 500;
              height: 25px;
            " @click="clearSearch()" v-if="selecteLabels.length > 0">清空筛选
                    </span>
                    <a-icon type="left" style="font-size: 18px" @click="changefilerDisplay(0)" />
                </p>
            </div>
            <filter-music @labelsChange="onChangeLabels" :dataList="allLabels" :selecteLabelIds="selecteLabelIds"
                :style="filterWrapStyle" class="filter_music_wrap"></filter-music>
        </div>

        <div class="filter_table_content" :style="tableStyle">
            <div class="search-wrap">
                <!-- <a-row type="flex" justify="center" align="top"> -->
                <div style="position:relative;height:60px;">
                    <span style="position:absolute;width:120px;height:60px;left:0px;">
                        <!-- <select class="search-select" style="font-size: 16px;line-height: 60px;width:100px;position:absolute;left:10px;height:60px;
                        -webkit-appearance: none;-moz-appearance: none;appearance: none;background-color: #F9FAFA;border:none;">
            <option v-for="x in xuanze" :key="x.id" style="height: 40px;margin-bottom:10px;">{{x.name}}</option>
          </select> -->
                        <div style="width:140px;">
                            <div style="width:120px;height:60px; text-align: left;position:relative;font-size:16px;cursor: pointer;"
                                id="select" @click="onClickSelect()">
                                <div
                                    style="-moz-user-select: none;-khtml-user-select: none;user-select: none;width:80px;margin-left:10px;position:absolute;">
                                    {{xuanze[selectIndex].name}}</div>
                                <!-- <div style="font-size:28px;position:absolute;left:70px;width:20px;height:50px;margin-top:6px;" v-if="onSelectActive">˄</div> -->
                                <img style="font-size:28px;position:absolute;left:80px;width:18px;height:13px;margin-top:25px;-webkit-transform:rotate(180deg);"
                                    v-if="onSelectActive" src="@/assets/images/web/home/xiala.svg" />
                                <img style="font-size:28px;position:absolute;left:80px;width:18px;height:13px;margin-top:25px;"
                                    v-if="!onSelectActive" src="@/assets/images/web/home/xiala.svg" />
                                <div
                                    style="position:absolute;left:109px;width:1px;height:20px;margin-top:20px;border:1px solid #E2E5E9;">
                                </div>
                            </div>

                            <!-- <Transition name="fadeslider"> -->
                            <div class="slider-bg" v-show="onSelectActive" id="fadeslider">
                                <!-- <TransitionGroup  name="fade"> -->
                                <div class="slider-cell" v-for="x in xuanze" :key="x.id" @click="onSelect(x.id)"
                                    @mouseenter="onPerSelect(x.id)" name="fadeslider"
                                    :class="{'selectActive':perSelectIndex==x.id,'selectNoActive':perSelectIndex!=x.id}">
                                    {{x.name}}</div>
                                <!-- </TransitionGroup > -->
                            </div>
                            <!-- </Transition> -->
                        </div>
                    </span>
                    <!-- <a-col
            :xs="10"
            :sm="10"
            :md="10"
            :lg="5"
            :xl="5"
            style="text-align: left":mouseover="onPerSelect(x.id)"
          > -->
                    <span style="width:60%;position:absolute;left:120px;height:60px;">
                        <input type="text" class="search-input" :placeholder="placeholder"
                            onblur="this.placeholder='在60万+音乐中搜索'" v-model="searchValue" @keydown.enter="onSearch"
                            style="position:absolute;left:0px;height:60px;width:100%;" />
                    </span>
                    <!-- </a-col> -->

                    <!-- <a-col
            :xs="3"
            :sm="3"
            :md="3"
            :lg="3"
            :xl="3"
            style="
              border-left: 1px solid #e2e5e9;
              background: #15110f;
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
              cursor: pointer;
            "
            @click="onSearch"> -->
                    <span style="
              border-left: 1px solid #e2e5e9;
              background: #15110f;
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
              cursor: pointer;
              float: right;
              width: 160px;
            " @click="onSearch">
                        <img src="@/assets/images/web/home/header_search.svg" />
                        <span style="font-size: 20px; color: #fff; vertical-align: middle">搜索</span>
                    </span>
                    <!-- </a-col> -->
                    <!-- </a-row> -->
                </div>
            </div>

            <div class="table_head">
                <!-- <a-row type="flex" justify="center" align="top"> -->
                <!-- <a-row type="flex" align="top"> -->
                <div style="position:relative;width:100%;height:40px;border-radius:20px">
                    <!-- <a-col
            :xs="11"
            :sm="11"
            :md="11"
            :lg="16"
            :xl="16"
            style="text-align: left"
          >
            
          </a-col> -->
                    <div style="float:left;width:960px" v-show="selecteLabels.length > 0">
                        <span style="margin-left:10px">标签：</span>
                        <span class="labels-item" style="padding-top:2px;padding-bottom:2px;"
                            v-for="item in selecteLabels" :key="item.label_id" @click="onChangeLabels(item)">
                            <span style="font-size:12px;">{{ item.name }}</span>
                            <img src="@/assets/images/web/close.png" style="width: 12px" />
                        </span>
                        <span style="text-decoration:underline;cursor: pointer;" @click="onCleanLabels()">清除筛选</span>
                    </div>
                    <!-- <a-col
            :xs="10"
            :sm="10"
            :md="10"
            :lg="10"
            :xl="16"
            style="text-align: right"
          > -->
                    <div style="position:absolute; right:0px;width:200px">
                        <!-- <span class="head_total"> 排序方式： </span> -->
                        <span class="sort_tag" :selected="searchType == 2 && search_sel == 1"
                            @click="changeSearchType(2)">
                            综合排序
                        </span>
                        <span> | </span>
                        <span class="sort_tag" :selected="searchType == 1 && search_sel == 1"
                            @click="changeSearchType(1)">
                            热门推荐
                        </span>
                        <span> | </span>
                        <span class="sort_tag" :selected="sort_type == 5 && sort_sel == 1" @click="changeSort(5)">
                            最新上架
                        </span>
                    </div>
                    <!-- </a-col> -->
                    <!-- <a-col
            :xs="14"
            :sm="14"
            :md="14"
            :lg="14"
            :xl="8"
            style="text-align: right; padding-right: 1%"
          >
          <div v-show = "musicParams.type == 4">
            <span class="head_total"> 类型： </span>
            <a-button
              class="type_button"
              :selected="selectSongidx == 0"
              @click="changeSong('0')"
              >全部</a-button
            >
            <a-button
              class="type_button"
              :selected="selectSongidx == 1"
              @click="changeSong('1')"
              >会员专区</a-button
            >
            <a-button
              class="type_button"
              :selected="selectSongidx == 2"
              @click="changeSong('2')"
              >零售专区</a-button
            >
          </div>
          </a-col> -->
                    <!-- </a-row> -->
                </div>
            </div>
            <music-table class="table_bottom" id="boxTable" :request="this.$axios.Music_List" :isImmediate="isImmediate"
                :params="musicParams" :is-more="false" :pagination="pagination" @changetotalNum="changetotalNum"
                @labelsChange="onChangeLabels" :selecteLabelIds="selecteLabelIds" ref="musictable">
            </music-table>
            <div class="app-footer2" v-if='route =="allMusic"||route =="allSound"||route =="vipMusic" '>
                <div style="margin:0 auto;width: 1200px;">北京火星火翎文化科技有限公司 | Copyright © 2020-2022. All Rights Reserved |
                    <a href= "https://beian.miit.gov.cn/"  rel="noreferrer" target="_blank" style="color: gray;">备案号：京ICP备2020045875号</a><span><img sytle="width:12px;height:13px;" src="/images/web/icon2.png"></span><a href= "https://beian.mps.gov.cn/#/query/webSearch?code=11010502053869\" 
                    rel="noreferrer" target="_blank" style="color: rgba(0, 0, 0, 1);">京公网安备11010502053869</a >  | 京网文（2021）0831-227号</div>
            </div>
        </div>
    </div>
</template>

<script>
    import MusicTable from "@/pages/components/music/NewMusicTableAllMusic.vue";
    import FilterMusic from "@/pages/components/music/filter/NewFilterMusic.vue";

    import { Icon } from "ant-design-vue";

    export default {
        data() {
            return {
                musicParams: { type: 3 },
                isMore: false,
                pagination: {
                    show_pagin: true,
                    "show-quick-jumper": true,
                    "default-current": 1,
                    total: 0,
                    pageSize: 20,
                    current: 0,
                    onChange: this.onChangePage,
                    hideOnSinglePage: true,
                    style: {
                        float: "none",
                        "text-align": "center",
                    },
                },
                searchValue: this.$route.query.q || "",
                searchType: 2,
                sort_type: 11, //排序方式
                time_up: 0, //时间从上到下
                speed_up: 0, //速度从上到下
                search_sel: 1,
                sort_sel: 0,
                totalNum: "",
                selecteLabels: [], //选中的标签信息
                defaultLabels: this.$route.query.labels || "",
                allLabels: [], //标签数据
                formatLabels: [], //标签数据format
                selecteLabelIds: [], //选中的标签ID api会用到
                isImmediate: false,
                selectSongidx: 0,
                isfixed: true,
                tableStyle: "width:100%",
                filter_button: true,
                filterStyle: "",
                filterWrapStyle: "",
                xuanze: [{ id: 0, name: "全部曲库" }, { id: 1, name: "会员专区" }, { id: 2, name: "音效曲库" }],
                onSelectActive: false,
                selectIndex: 0,
                perSelectIndex: 0,
                route: "",
                placeholder: '',
            };
        },
        components: {
            MusicTable,
            FilterMusic,
            AIcon: Icon,
        },
        created: function () {
            // console.log(this.$route.params.id);

            this.initpara();
            let labels = this.$route.query.labels || "";
            if (labels == "") {
                this.isImmediate = true;
            }
            let keyword = this.searchValue || "";
            if (keyword != "") {
                let params = {
                    keyword: keyword,
                };
                this.musicParams = Object.assign({}, this.musicParams, params);
            }
            let sort_params = {
                sort_type: 2,
            };
            this.musicParams = Object.assign({}, this.musicParams, sort_params);
            // console.log("create --- "+this.selectSongidx);
        },
        watch: {
            "$route"() {
                // if (this.$route.query.id == 1) {
                //     this.musicParams = { type: 2 ,library_type: 0};
                //     this.getLabels(2);
                //     this.selectSongidx = 0;
                //     this.selectIndex = 0;
                //     this.perSelectIndex = 0;
                // }else if (this.$route.query.id == 7) {
                //     this.musicParams = { type: 2 ,library_type: 1};
                //     this.getLabels(2);
                //     this.selectSongidx = 1;
                //     this.selectIndex = 1;
                //     this.perSelectIndex = 1;
                // }else{
                //     this.musicParams = { type: 3 ,library_type: 1};
                //     this.getLabels(3);
                //     // this.changeSong(1);
                //     this.selectSongidx = 1;
                //     this.selectIndex = 2;
                //     this.perSelectIndex = 2;
                // }
                this.initpara();
                this.pagination.current = 1;
                let keyword = this.searchValue || "";
                if (keyword != "") {
                    this.musicParams['keyword'] = keyword;
                } else {

                    delete this.musicParams.keyword;
                    // console.log("delte",this.musicParams)
                }
                this.$refs.musictable.refresh(this.musicParams);
                // console.log("watch --- ");
                this.route = this.$route.name;
            }
        },
        mounted() {
            window.addEventListener("scroll", this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
            let newhel = document.documentElement.clientHeight - 64;
            this.filterStyle = "height:" + newhel + "px;";
            this.filterWrapStyle = "height:" + (newhel - 64) + "px;";

            document.addEventListener('click', (e) => {
                const contentWrap = document.getElementById("select");
                if (contentWrap.contains(e.target)) return
                // this.onSelectActive = false
                this.close();
            })
            this.route = this.$route.name;
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.handleScroll);
        },
        methods: {
            handleScroll() {
                // let scrollTop =
                //   window.pageYOffset ||
                //   document.documentElement.scrollTop ||
                //   document.body.scrollTop; // 滚动条偏移量
                // let TableHeight = this.$el.querySelector(".filter_table_content");
                // let clientHeight = TableHeight.scrollHeight;
                // // let newhel = clientHeight - scrollTop - 90;
                // let newhel = document.documentElement.clientHeight -90 -64 ;
                // this.filterStyle = "height:" + newhel + "px;";
                // this.filterWrapStyle = "height:" + (newhel - 64) + "px;";
            },
            open() {
                var elems = document.getElementsByName("fadeslider");
                var ele = document.getElementById("fadeslider");
                var count = 0;
                var id = setInterval(frame, 5);
                var opacity = 0.4;
                var pos = 0;
                ele.style.marginTop = pos + 'px';
                ele.style.opacity = 1;
                for (var elem of elems) {
                    elem.style.opacity = opacity;
                }
                function frame() {
                    if (count == 20) {
                        clearInterval(id);
                    } else {
                        count++;
                        pos += 1;
                        pos = Math.min(pos, 6);
                        ele.style.marginTop = pos + 'px';
                        opacity += 0.03;
                        // ele.style.opacity=opacity;
                        for (var elem of elems) {
                            elem.style.opacity = opacity;
                        }
                    }
                }

            },
            close() {
                var that = this;
                var elems = document.getElementsByName("fadeslider");
                var ele = document.getElementById("fadeslider");
                var count = 0;
                var id = setInterval(frame, 5);
                var opacity = 1;
                var pos = 6;
                ele.style.marginTop = pos + 'px';
                ele.style.opacity = 1;
                for (var elem of elems) {
                    elem.style.opacity = opacity;
                }
                function frame() {
                    if (count == 20) {
                        clearInterval(id);
                        that.onSelectActive = false;
                    } else {
                        count++;
                        pos -= 1;
                        pos = Math.max(pos, 0);
                        ele.style.marginTop = pos + 'px';
                        opacity -= 0.03;
                        // ele.style.opacity=opacity;
                        for (var elem of elems) {
                            elem.style.opacity = opacity;
                        }
                    }
                }
            },
            initpara() {
                if (this.$route.query.id == 1) {
                    this.musicParams = { type: 2, library_type: 0 };
                    this.getLabels(2);
                    this.selectSongidx = 0;
                    this.selectIndex = 0;
                    this.perSelectIndex = 0;
                    this.placeholder = '在60万+音乐中搜索';
                } else if (this.$route.query.id == 7) {
                    this.musicParams = { type: 2, library_type: 1 };
                    this.getLabels(2);
                    this.selectSongidx = 1;
                    this.selectIndex = 1;
                    this.perSelectIndex = 1;
                    this.placeholder = '可输入场景、风格、情绪、配器等关键词搜索';
                } else if (this.$route.query.id == 2) {
                    this.musicParams = { type: 2 };
                    this.selectSongidx = 1;
                    this.getLabels(2);
                }
                else {
                    this.musicParams = { type: 3, library_type: 1 };
                    this.selectSongidx = 1;
                    this.getLabels(3);
                    this.selectIndex = 2;
                    this.perSelectIndex = 2;
                    this.placeholder = '可输入综艺、自然、游戏、转场等关键词';
                }
            },
            changefilerDisplay(idx) {
                if (idx == 1) {
                    this.isfixed = false;
                    this.filter_button = false;
                    this.tableStyle = "margin-left:16%;width:84%;";
                } else {
                    this.isfixed = true;
                    this.filter_button = true;
                    this.tableStyle = "margin-left:0;width:100%;";
                }
            },
            clearSearch() {
                if (this.selecteLabels.length <= 0) {
                    return false;
                }
                this.selecteLabels = [];
                this.selecteLabelIds = [];
                let params = {
                    label_ids: [],
                };
                this.freshTable(params);
            },
            getLabels(_t) {

                this.$axios.CommonLabels({ type: 1, type2: _t }).then((res) => {
                    if (res.data.code == 0) {
                        this.allLabels = res.data.data.list;
                        for (let idx = 0; idx < this.allLabels.length; idx++) {
                            let label_data = this.allLabels[idx].child;
                            for (
                                let child_idx = 0;
                                child_idx < label_data.length;
                                child_idx++
                            ) {
                                this.formatLabels[label_data[child_idx].label_id] =
                                    label_data[child_idx];
                            }
                        }
                        this.getDefaultLabels();
                    }
                });
            },
            getDefaultLabels() {
                let labels = this.$route.query.labels || "";
                if (labels) {
                    labels = labels.split(",");
                    for (let idx = 0; idx < labels.length; idx++) {
                        if (this.formatLabels[labels[idx]]) {
                            this.selecteLabels.push(this.formatLabels[labels[idx]]); //push才会同时改变视图
                            this.selecteLabelIds.push(labels[idx]);
                        }
                    }
                    //刷新table
                    let params = {
                        label_ids: this.selecteLabelIds,
                    };
                    this.isImmediate = true;
                    this.freshTable(params);
                }
            },
            freshTable(params) {
                this.musicParams = Object.assign({}, this.musicParams, params);
                setTimeout(() => {
                    this.handleScroll();
                }, 450);
            },
            onSearch() {
                // 
                let keyword = this.searchValue || "";
                // console.log("-----",keyword)
                if (this.selectIndex == 0) {
                    if (this.$router.history.current.path == "/allmusic") {
                        let params = {
                            keyword: keyword,
                        };
                        this.freshTable(params);
                    } else {
                        this.$router.push({
                            path: encodeURI(`/allmusic?id=1&q=${keyword}`)
                        })
                    }

                } else if (this.selectIndex == 1) {
                    // console.log(this.$router.history.current.path)
                    if (this.$router.history.current.path == "/vipmusic") {
                        let params = {
                            keyword: keyword,
                        };
                        // console.log(params)
                        this.freshTable(params);
                    } else {
                        this.$router.push({
                            path: encodeURI(`/vipmusic?id=7&q=${keyword}`)
                        })
                    }
                } else if (this.selectIndex == 2) {
                    if (this.$router.history.current.path == "/allSound") {
                        let params = {
                            keyword: keyword,
                        };
                        this.freshTable(params);
                    } else {
                        this.$router.push({
                            path: encodeURI(`/allSound?id=6&q=${keyword}`)
                        })
                    }
                }
                // if (keyword != "") {
                //   let params = {
                //     keyword: keyword,
                //   };
                //   this.freshTable(params);
                // } else {
                //   let params = {
                //     keyword: "",
                //   };
                //   this.freshTable(params);
                // }
            },
            changeSearchType(sort) {
                this.searchType = sort;
                this.search_sel = 1;
                this.sort_sel = 0;
                let params = {
                    sort_type: sort,
                    sort: "",
                };
                this.freshTable(params);
            },
            changeSort(sort) {
                this.sort_sel = 1;
                this.search_sel = 0;
                if (sort == 0) {
                    if (this.speed_up != 0) {
                        sort = 1; //从慢到快
                        this.speed_up = 0;
                    } else {
                        this.speed_up = 1;
                    }
                }
                if (sort == 3) {
                    //短到长 长到短
                    if (this.time_up != 0) {
                        sort = 4;
                        this.time_up = 0;
                    } else {
                        this.time_up = 1;
                    }
                }
                let params = {
                    sort: sort,
                };
                if (sort == 5) {
                    params = {
                        sort: 0,
                        sort_type: 3,
                    };
                }
                this.sort_type = sort;

                this.freshTable(params);
            },
            //切换曲库
            changeSong(idx) {
                this.selectSongidx = idx;
                let params = {
                    library_type: idx,
                };
                this.freshTable(params);
            },
            onChangeLabels(params) {
                let param = {};
                if (params.label_id) {
                    let idx = this.selecteLabelIds.indexOf(params.label_id);
                    if (idx != "-1") {
                        this.selecteLabelIds.splice(idx, 1);
                        this.selecteLabels.splice(idx, 1);
                    } else {
                        if (this.selecteLabelIds.length < 6) {
                            this.selecteLabelIds.push(params.label_id);
                            this.selecteLabels.push(params);
                        }
                    }
                    param.label_ids = this.selecteLabelIds;
                }
                if (params.bpm) {
                    param.bpm = params.bpm;
                }
                if (params.duration) {
                    param.duration = params.duration;
                }
                this.freshTable(param);
            },
            onCleanLabels() {
                this.selecteLabelIds = [];
                this.selecteLabels = [];
                let param = {};
                param.label_ids = this.selecteLabelIds;

                this.freshTable(param);
            },
            onChangePage(params) {
                this.pagination.current = params;
                this.pagination = Object.assign({}, this.pagination);
                document.documentElement.scrollTop = 0;
            },
            changetotalNum(params) {
                this.totalNum = params;
            },
            onClickSelect() {

                if (!this.onSelectActive) {
                    this.onSelectActive = true;
                    this.open();
                } else {
                    this.close();
                }

            },
            onSelect(index) {
                console.log(index)
                this.selectIndex = index;
                this.perSelectIndex = index;


            },
            onPerSelect(index) {
                this.perSelectIndex = index;
            }

        },
    };
</script>
<style lang="scss" scoped>
    .all-music {
        .filter_open {
            display: inline-block;
            position: fixed;
            width: 72px;
            height: 48px;
            line-height: 48px;
            left: 0px;
            top: 90px;
            color: #fff;
            font-size: 14px;
            background: #000;
            border-radius: 0 24px 24px 0;
            text-align: center;
            cursor: pointer;
            opacity: 1;
            font-weight: 500;
        }

        .title {
            height: 88px;
            line-height: 88px;
            border-bottom: 1px solid $border-color;
        }

        .labels-item {
            display: inline-block;
            background: #332f2d;
            border-radius: 5px;
            line-height: 20px;
            margin-right: 10px;
            padding: 0px 10px;
            cursor: pointer;

            span {
                display: inline-block;
                height: 20px;
                font-size: 16px;
                font-weight: 400;
                color: #ffffff;
            }

            img {
                display: inline-block;
                margin-left: 7px;
                margin-top: -2px;
            }
        }

        // 搜索
        .search-wrap {
            border-radius: 10px;
            margin: 24px 0px;
            border: 1px solid #e2e5e9;
            width: 100%;
            line-height: 60px;
            display: block;
            text-align: center;

            .search-icon {
                width: 24px;
                height: 24px;
                display: inline-block;
            }

            .search-input {
                width: 90%;
                border: none;
                outline: none;
                border-radius: 10px;
                box-shadow: none;
                font-size: 16px;
                font-weight: 400;
                color: #a1a6af;
                background: #f9fafa;
                padding-left: 12px;
            }

            .search-select:hover {
                border: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }
        }

        .filter_music_wrap {
            overflow-y: scroll;
            height: auto;
        }

        .filter_music {
            transition: left 0.4s;
            width: 260px;
            display: inline-block;
            vertical-align: top;
            border-radius: 2px;
            border: 1px solid #e2e5e9;
            position: fixed;
            left: 0px;
            top: 64px;
            bottom: 0rem;
            z-index: 4;

            .filter_head {
                display: block;
                border-bottom: 1px solid #e2e5e9;
                padding-top: 11px;
                line-height: 25px;

                h3 {
                    display: inline-block;
                    padding-left: 24px;
                    width: 50%;
                    font-size: 18px;
                    font-weight: 500;
                    color: #333333;
                }

                p {
                    display: inline-block;
                    width: 50%;
                    text-align: right;
                    padding-right: 5%;
                    cursor: pointer;
                }
            }
        }

        .filter_table_content {
            display: inline-block;
            transition: all ease-in 0.2s;

            .table_head {
                line-height: 40px;
                background: #f9fafb;
                border-radius: 4px;
                border: 1px solid #e5e7eb;
                font-size: 14px;
                font-weight: 400;
                color: #333333;

                .head_total {
                    margin-left: 1%;
                }

                .sort_tag {
                    cursor: pointer;
                    display: inline-block;
                    line-height: 40px;
                }

                .sort_tag[selected] {
                    color: #ff7b13;
                }

                .type_button {
                    margin-right: 1%;
                    border-radius: 2px;
                }

                .type_button[selected] {
                    background: #ff7b13;
                    color: #fff;
                }
            }

            .table_bottom {
                min-height: 600px;
                margin-top: 10px;
                margin-bottom: 30px;
            }
        }

        .selectActive {
            background: #ff7b13;
            color: white;
        }

        .selectNoActive {
            background: #F9FAFA;
            color: black;
        }

        .app-footer2 {
            width: 100%;
            height: 4rem;
            position: relative;
            bottom: 0;
            font-size: 1.2rem;
            padding-top: 1.1rem;
            padding-bottom: 1.6rem;
            align-items: center;
            justify-content: center;
        }

        .slider-bg {
            border: 1px solid #E8E8E8;
            width: 122px;
            background-color: #F9FAFA;
            margin-top: 6px;
            transform-origin: 50% 0;
        }

        .slider-cell {
            width: 120px;
            height: 40px;
            position: relative;
            z-index: 2;
            line-height: 40px;
            font-size: 16px;
            cursor: pointer;
        }

        // .fadeslider-enter-to{
        //   // transform:translateX(1);
        //   // opacity: 1;
        //   transform:translateY(0px);
        // }
        // .fadeslider-enter-active,
        // .fadeslider-leave-active {

        // transition:  0.1s ease;
        // }

        // .fadeslider-enter-from,
        // .fadeslider-leave-to {
        // transform:translateY(-10px);
        // // opacity: 0.8;
        // }
    }

    // mobile
    @media only screen and (max-width: $screen-width-md) {
        .all-music {
            .search-wrap {

                //line-height: 24px;
                .search-icon {
                    width: 3%;
                    height: 3%;
                }

                .search-input {
                    width: 40%;
                    font-size: 12px;
                }

                .search-labels {
                    width: 100%;
                    display: block;

                    .labels-item {
                        height: 24px;
                        line-height: 24px;
                        padding: 0 2px;

                        span {
                            font-size: 12px;
                        }

                        img {
                            width: 10%;
                        }
                    }
                }
            }

            .filter_table_content {

                //width: 62%;
                .table_head {
                    h3 {
                        width: 50%;
                        font-size: 14px;
                    }

                    .table_head_tab {
                        width: 50%;

                        span {
                            font-size: 12px;
                        }

                        span[disabled] {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
</style>